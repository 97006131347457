import React from "react"
import Layout from "../components/layout"
import * as R from 'ramda'
import { graphql } from "gatsby"
import SEO from '../components/seo'
import Grid from '@material-ui/core/Grid'

//import loadable from '@loadable/component'
import importedComponent from 'react-imported-component';

const Hero = importedComponent(() => import('../components/Hero'));
const ReferenceHandler = importedComponent(() => import('../components/ReferenceHandler'));

const slugify = require('slugify');

function createMarkup(vr360) {
  return {
    __html:
      `<iframe src=${vr360} width="100%" height="450" frameborder="0" style="border:0;" allowfullscreen="" />`,
  };
}

const IndexPage = ({data, pageContext, location}) => {
  const pageContent = R.path(['indexPage'], data);
  const references = R.path(['dynaaminenSisalto'], pageContent);
  const vr360 = R.path(['vr360'], pageContent);
  let heroData = {};
  if(references){
    if (R.path(['dynaaminenSisalto'], pageContent).find(n => n.__typename === 'ContentfulPaanosto')) {
      heroData = R.path(['dynaaminenSisalto'], pageContent).find(n => n.__typename === 'ContentfulPaanosto');
    }
  }

  const brandSettings = R.path(['brandSettings'], data);
  const allArticles = R.path(['allArticles', 'nodes'], data);

  const brandSTT = R.path(['allInternalReleases', 'nodes', 0, 'releases'], data);

  const renamedBrandSTT = brandSTT.map(function (node) {
    return {
      id: node.alternative_id,
      published: node.published,
      paakuva: node.mainImage ? node.mainImage.thumbnail_16_9 : null,
      sisalto: {
        sisalto: node.leadTextOrBodyStrip,
      },
      sivunOtsikko: node.title,
      sivupolku: slugify(node.title, { remove: /[*+~.()/'"!:@]/g, lower: true }),
      avainsanat: node.keywords
    };
  });
  const contentfulArticles = allArticles.map(node => {
    return {
      ...node,
      published: node.published2 ? node.published2 : node.published,
    };
  })

  const mergedArticles = contentfulArticles.concat(renamedBrandSTT).sort((a, b) => (a.published < b.published) ? 1 : -1);

  const instaNodes = R.path(['allInstaNode', 'nodes'], data);
  instaNodes.forEach(node => {
    node.type = "instagram";
  });
  const fbNodes = R.path(['allFacebookPosts', 'nodes'], data);
  fbNodes.forEach(node => {
    const dateObj = new Date(node.published)
    node.timestamp = dateObj.getTime()/1000
    node.username = node.from.name;
    node.type = "facebook";

    if(!node.image) {
      node.image = R.path(['brandSettings', 'logo'], data)
    }
  });
  const someNodes = fbNodes.concat(instaNodes).sort((a, b) => (a.timestamp < b.timestamp) ? 1 : -1);
  
  const sitename = R.path(['nimi'], brandSettings)
  const cuisine = R.path(['ravintolanTyyppi'], brandSettings)
  const seoTitle = R.path(['seoOtsikko'], pageContent)
  const seoDesc = R.path(['seoKuvaus', 'seoKuvaus'], pageContent)
  const someTitle = R.path(['someOtsikko'], pageContent) ? R.path(['someOtsikko'], pageContent) : seoTitle
  const someDesc = R.path(['someKuvaus', 'someKuvaus'], pageContent) ? R.path(['someKuvaus', 'someKuvaus'], pageContent) : seoDesc
  const metaImage = R.path(['paakuva', 'file', 'url'], pageContent)
  const pageURL = location.href
  const goal = R.path(['sivunTavoite'], pageContent)
  const service = R.path(['liiketoimintaAlue'], pageContent)
  const asiakaspolku = R.path(['asiakaspolku'], pageContent)

  return (
    <Layout>
      <SEO
        sitename={sitename}
        seoTitle={seoTitle}
        seoDesc={seoDesc}
        someTitle={someTitle}
        someDesc={someDesc}
        image={metaImage}
        url={pageURL}
        pageTemplate="etusivu"
        dataLayer={true}
        service={service}
        cuisine={cuisine}
        city="Helsinki"
        goal={goal}
        asiakaspolku={asiakaspolku}
      />
    <Hero data={{
      image: R.path(['paakuva', 'fluid'], pageContent),
      mobileImage: R.path(['paakuvaMobiili', 'fluid'], pageContent),
      title: R.path(['otsikko'], heroData),
      content: R.path(['sisaltoteksti', 'sisaltoteksti'], heroData),
      ctaVisible: R.path(['naytaNavigointinappi'], heroData),
      ctaText: R.path(['linkkiteksti'], heroData),
      ctaLink: R.path(['linkkiosoite'], heroData)
    }} />

    <ReferenceHandler
      slug="/" 
      posts={references} 
      articles={mergedArticles} 
      brandSettings={brandSettings}
      someNodes={someNodes}
    />

    { vr360 && 
      vr360.map(item => 
        <Grid container spacing={3}>
          <Grid item xs={1}></Grid>
          <Grid item xs={10}>
            <div dangerouslySetInnerHTML={createMarkup(item)} />
          </Grid>
          <Grid item xs={1}></Grid>
        </Grid>
      )
    }

  </Layout>
)
  }

export default IndexPage

// eslint-disable-next-line no-undef
export const pageQuery = graphql`

  query frontPageQuery($brand: String!) {
    indexPage: contentfulEtusivu(ketju: {sivupolku: {eq: $brand}}) {
      id
      vr360
      seoOtsikko
      seoKuvaus {
        seoKuvaus
      }
      paakuva {
        fluid(quality: 60, maxWidth: 1680) {
          ...GatsbyContentfulFluid_withWebp
        }
      }
      paakuvaMobiili {
        fluid(quality: 60, maxWidth: 600) {
          ...GatsbyContentfulFluid_withWebp
        }
      }
      dynaaminenSisalto {
        ... on Node {
          ... on ContentfulPaanosto {
            id
            otsikko
            linkkiosoite
            linkkiteksti
            naytaNavigointinappi
            sisaltoteksti {
              sisaltoteksti
            }
          }
          ... on ContentfulTeksti {
            id
            otsikko
            ingressi {
              ingressi
            }
            sisalto {
              sisalto
            }
          }
          ... on ContentfulKampanjanosto {
            id
            nimi
            kampanjat {
              ... on Node {
                ... on ContentfulKampanjalinkki {
                  id
                  otsikko
                  kuvausTeksti {
                    kuvausTeksti
                  }
                  kuva {
                    fluid(quality: 60, maxWidth: 1100) {
                      ...GatsbyContentfulFluid_withWebp
                    }
                  }
                  linkki
                  linkinTeksti
                  linkinKohde
                  kakkoslinkki
                  kakkoslinkinTeksti
                  kakkoslinkinKohde
                  kampanjanostonKoko
                }
                ... on ContentfulYleissivu {
                  __typename
                  id
                  otsikko : sivunOtsikko
                  sivupolku
                  kuvausTeksti : kuvausteksti {
                    kuvausTeksti: kuvausteksti
                  }
                  kuva: paakuva {
                    fluid(quality: 60, maxWidth: 1100) {
                      ...GatsbyContentfulFluid_withWebp
                    }
                  }
                  kampanjanostonKoko
                }
              }
            }
          }
          ... on ContentfulSomeFeed {
            id
            otsikko
          }
          ... on ContentfulUutislistaus {
            id
            otsikko
            avainsanat
          }
        }
      }
      sivunTavoite
      liiketoimintaAlue
      asiakaspolku
    }
    brandSettings: contentfulKetju(sivupolku: { eq: $brand }) {
      id
      nimi
      sivupolku
      ravintoloidenYlasivu {
        sivupolku
      }
      uutistenYlasivu {
        sivupolku
      }
      logo {
        fluid(quality: 80, maxWidth: 300) {
          ...GatsbyContentfulFluid_withWebp
        }
      }
      ravintolanTyyppi
    }
    allArticles: allContentfulUutissivu(filter: {ketju: {sivupolku: { eq: $brand }}}) {
      nodes {
        id
        published: createdAt
        sivunOtsikko
        sivupolku
        sisalto {
          sisalto
        }
        paakuva {
          fluid(quality: 60, maxHeight: 235) {
            ...GatsbyContentfulFluid_withWebp
          }
        }
      }
    }
    allInstaNode {
      nodes {
        id
        timestamp
        image: localFile {
          childImageSharp {
            fluid(quality: 80, maxWidth: 300) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        caption
        username
      }
    }
    allFacebookPosts {
      nodes {
        id
        from {
          id
          name
        }
        published: created_time
        caption: message
        image: full_picture
        url: permalink_url
      }
    }
    allInternalReleases(filter: {id: {ne: "dummy"}}) {
      nodes {
        releases {
          alternative_id
          mainImage {
            url
            thumbnail_16_9
          }
          published
          title
          leadTextOrBodyStrip
          body
          keywords
        }
      }
    }
  }

`;
